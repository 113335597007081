import { lazy, Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { Container } from 'semantic-ui-react';
import LoadingComponent from '../../app/layout/LoadingComponent';
import { UserIsAuthenticated } from '../../features/auth/authWrapper';
import NotFound from './NotFound';
//import OfflineMessage from "./OfflineMessage";

const AsyncHomePage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncHomePage" */ '../../features/home/HomePage'
  ),
);

const AsyncDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncDashboard" */ '../../features/dashboard/Dashboard'
  ),
);

const AsyncSessionDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSessionDashboard" */ '../../features/session/SessionDashboard/SessionDashboard'
  ),
);

const AsyncPlanDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncPlanDashboard" */ '../../features/plan/PlanDashboard/PlanDashboard'
  ),
);

const AsyncDocumentDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncDocumentDashboard" */ '../../features/document/DocumentDashboard/DocumentDashboard'
  ),
);

const AsyncSupervisionDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSupervisionDashboard" */ '../../features/supervision/SupervisionDashboard/SupervisionDashboard'
  ),
);

const AsyncReviewDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncReviewDashboard" */ '../../features/review/ReviewDashboard/ReviewDashboard'
  ),
);

const AsyncEventDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncEventDashboard" */ '../../features/event/EventDashboard/EventDashboard'
  ),
);

const AsyncNondirectDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncNondirectDashboard" */ '../../features/nondirect/NondirectDashboard/NondirectDashboard'
  ),
);

const AsyncSessionDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSessionDetailedPage" */ '../../features/session/SessionDetailed/SessionDetailedPage'
  ),
);

const AsyncPlanDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncPlanDetailedPage" */ '../../features/plan/PlanDetailed/PlanDetailedPage'
  ),
);

const AsyncDocumentDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncDocumentDetailedPage" */ '../../features/document/DocumentDetailed/DocumentDetailedPage'
  ),
);

const AsyncSupervisionDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSupervisionDetailedPage" */ '../../features/supervision/SupervisionDetailed/SupervisionDetailedPage'
  ),
);

const AsyncReviewDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncReviewDetailedPage" */ '../../features/review/ReviewDetailed/ReviewDetailedPage'
  ),
);

const AsyncEventDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncEventDetailedPage" */ '../../features/event/EventDetailed/EventDetailedPage'
  ),
);

const AsyncNondirectDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncNondirectDetailedPage" */ '../../features/nondirect/NondirectDetailed/NondirectDetailedPage'
  ),
);

const AsyncSessionForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSessionForm" */ '../../features/session/SessionForm/SessionForm'
  ),
);

const AsyncPlanForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncPlanForm" */ '../../features/plan/PlanForm/PlanForm'
  ),
);

const AsyncDocumentForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncDocumentForm" */ '../../features/document/DocumentForm/DocumentForm'
  ),
);

const AsyncSupervisionForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSupervisionForm" */ '../../features/supervision/SupervisionForm/SupervisionForm'
  ),
);

const AsyncReviewForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncReviewForm" */ '../../features/review/ReviewForm/ReviewForm'
  ),
);

const AsyncEventForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncEventForm" */ '../../features/event/EventForm/EventForm'
  ),
);

const AsyncNondirectForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncNondirectForm" */ '../../features/nondirect/NondirectForm/NondirectForm'
  ),
);

const AsyncPeopleDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncPeopleDashboard" */ '../../features/user/PeopleDashboard/PeopleDashboard'
  ),
);

const AsyncUserDetailedPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncUserDetailedPage" */ '../../features/user/UserDetailed/UserDetailedPage'
  ),
);

const AsyncManageUserForm = lazy(() =>
  import(
    /* webpackChunkName: "AsyncManageUserForm" */ '../../features/user/ManageUsers/ManageUserForm'
  ),
);

const AsyncSettingsDashboard = lazy(() =>
  import(
    /* webpackChunkName: "AsyncSettingsDashboard" */ '../../features/user/Settings/SettingsDashboard'
  ),
);

const AsyncNavBar = lazy(() =>
  import(
    /* webpackChunkName: "AsyncNavBar" */ '../../features/nav/NavBar/NavBar'
  ),
);

const AsyncNavBarMenu = lazy(() =>
  import(
    /* webpackChunkName: "AsyncNavBarMenu" */ '../../features/nav/NavBar/NavBarMenu'
  ),
);

const AsyncModalManager = lazy(() =>
  import(
    /* webpackChunkName: "AsyncModalManager" */ '../../features/modals/ModalManager'
  ),
);

const AsyncActivityLog = lazy(() =>
  import(
    /* webpackChunkName: "AsyncActivityLog" */ '../../features/ActivityLog/ActivityLog'
  ),
);

const AsyncWikiPage = lazy(() =>
  import(
    /* webpackChunkName: "AsyncWikiPage" */ '../../features/wiki/pages/WikiPage'
  ),
);

const AsyncGuides = lazy(() =>
  import(
    /* webpackChunkName: "AsyncGuides" */ '../../features/wiki/pages/Guides'
  ),
);

const AsyncHandbooks = lazy(() =>
  import(
    /* webpackChunkName: "AsyncHandbooks" */ '../../features/wiki/pages/Handbooks'
  ),
);

const AsyncResources = lazy(() =>
  import(
    /* webpackChunkName: "AsyncResources" */ '../../features/wiki/pages/Resources'
  ),
);

const AsyncReports = lazy(() =>
  import(
    /* webpackChunkName: "AsyncResources" */ '../../features/report/ReportDashboard'
  ),
);

const App = () => {
  return (
    <div>
      <Suspense fallback={<LoadingComponent />}>
        <AsyncModalManager />
      </Suspense>
      <Suspense fallback={<LoadingComponent />}>
        <Route exact path="/" component={AsyncHomePage} />
      </Suspense>

      <Route
        path="/(.+)"
        render={() => (
          <div>
            <Suspense fallback={<LoadingComponent />}>
              <AsyncNavBar />
            </Suspense>

            {/* <Offline>
              <OfflineMessage />
            </Offline> */}

            <Suspense fallback={<LoadingComponent />}>
              <AsyncNavBarMenu />
            </Suspense>

            <Container className="main">
              <Suspense fallback={<LoadingComponent />}>
                <Switch>
                  <Route
                    path="/home"
                    component={UserIsAuthenticated(AsyncDashboard)}
                  />
                  <Route
                    path="/sessions"
                    component={UserIsAuthenticated(AsyncSessionDashboard)}
                  />
                  <Route
                    path="/plans"
                    component={UserIsAuthenticated(AsyncPlanDashboard)}
                  />
                  <Route
                    path="/documents"
                    component={UserIsAuthenticated(AsyncDocumentDashboard)}
                  />
                  <Route
                    path="/supervisions"
                    component={UserIsAuthenticated(AsyncSupervisionDashboard)}
                  />
                  <Route
                    path="/reviews"
                    component={UserIsAuthenticated(AsyncReviewDashboard)}
                  />
                  <Route
                    path="/events"
                    component={UserIsAuthenticated(AsyncEventDashboard)}
                  />
                  <Route
                    path="/nondirects"
                    component={UserIsAuthenticated(AsyncNondirectDashboard)}
                  />
                  <Route
                    path="/activity"
                    component={UserIsAuthenticated(AsyncActivityLog)}
                  />
                  <Route
                    path="/session/:id"
                    component={UserIsAuthenticated(AsyncSessionDetailedPage)}
                  />
                  <Route
                    path="/plan/:id"
                    component={UserIsAuthenticated(AsyncPlanDetailedPage)}
                  />
                  <Route
                    path="/document/:id"
                    component={UserIsAuthenticated(AsyncDocumentDetailedPage)}
                  />
                  <Route
                    path="/supervision/:id"
                    component={UserIsAuthenticated(
                      AsyncSupervisionDetailedPage,
                    )}
                  />
                  <Route
                    path="/review/:id"
                    component={UserIsAuthenticated(AsyncReviewDetailedPage)}
                  />
                  <Route
                    path="/event/:id"
                    component={UserIsAuthenticated(AsyncEventDetailedPage)}
                  />
                  <Route
                    path="/nondirect/:id"
                    component={UserIsAuthenticated(AsyncNondirectDetailedPage)}
                  />
                  <Route
                    path="/myNetwork"
                    component={UserIsAuthenticated(AsyncPeopleDashboard)}
                  />
                  <Route
                    path="/profile/:id"
                    component={UserIsAuthenticated(AsyncUserDetailedPage)}
                  />
                  <Route
                    path="/settings"
                    component={UserIsAuthenticated(AsyncSettingsDashboard)}
                  />
                  <Route
                    path={['/createSession', '/manageSession/:id']}
                    component={UserIsAuthenticated(AsyncSessionForm)}
                  />
                  <Route
                    path={['/createPlan', '/managePlan/:id']}
                    component={UserIsAuthenticated(AsyncPlanForm)}
                  />
                  <Route
                    path={['/createDocument', '/manageDocument/:id']}
                    component={UserIsAuthenticated(AsyncDocumentForm)}
                  />
                  <Route
                    path={['/createSupervision', '/manageSupervision/:id']}
                    component={UserIsAuthenticated(AsyncSupervisionForm)}
                  />
                  <Route
                    path={['/createReview', '/manageReview/:id']}
                    component={UserIsAuthenticated(AsyncReviewForm)}
                  />
                  <Route
                    path={['/manageUsers', '/manageUsers']}
                    component={UserIsAuthenticated(AsyncManageUserForm)}
                  />
                  <Route
                    path={['/createEvent', '/manageEvent/:id']}
                    component={UserIsAuthenticated(AsyncEventForm)}
                  />
                  <Route
                    path={['/createNondirect', '/manageNondirect/:id']}
                    component={UserIsAuthenticated(AsyncNondirectForm)}
                  />
                  <Route
                    path="/wiki"
                    component={UserIsAuthenticated(AsyncWikiPage)}
                  />
                  <Route
                    path="/guides"
                    component={UserIsAuthenticated(AsyncGuides)}
                  />
                  <Route
                    path="/handbooks"
                    component={UserIsAuthenticated(AsyncHandbooks)}
                  />
                  <Route
                    path="/resources"
                    component={UserIsAuthenticated(AsyncResources)}
                  />
                  <Route
                    path="/reports"
                    component={UserIsAuthenticated(AsyncReports)}
                  />
                  <Route path="/error" component={NotFound} />
                  <Route component={NotFound} />
                </Switch>
              </Suspense>
            </Container>
          </div>
        )}
      />
    </div>
  );
};

export default App;
