import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_PLAN,
  DELETE_PLAN,
  FETCH_PLANS,
  UPDATE_PLAN,
} from "./planConstants";

const initialState = [];

export const createPlan = (state, payload) => {
  return [...state, Object.assign({}, payload.plan)];
};

export const updatePlan = (state, payload) => {
  return [
    ...state.filter((plan) => plan.id !== payload.plan.id),
    Object.assign({}, payload.plan),
  ];
};

export const deletePlan = (state, payload) => {
  return [...state.filter((plan) => plan.id !== payload.planId)];
};

export const fetchPlans = (state, payload) => {
  return payload.plans;
};

export default createReducer(initialState, {
  [CREATE_PLAN]: createPlan,
  [UPDATE_PLAN]: updatePlan,
  [DELETE_PLAN]: deletePlan,
  [FETCH_PLANS]: fetchPlans,
});
