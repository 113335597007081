import { useHistory, withRouter } from "react-router-dom";
import { Header, Message } from "semantic-ui-react";

const NotFound = () => {
  const history = useHistory();
  return (
    <Message>
      <Message.Header>Page not found.. </Message.Header>
      <br />
      <Header
        as="h5"
        textAlign="center"
        onClick={() => history.push("/sessions")}
        style={{ cursor: "pointer" }}
      >
        Click here to go home
      </Header>
    </Message>
  );
};

export default withRouter(NotFound);
