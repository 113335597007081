import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_NONDIRECT,
  DELETE_NONDIRECT,
  FETCH_NONDIRECTS,
  UPDATE_NONDIRECT,
} from "./nondirectConstants";

const initialState = [];

export const createNondirect = (state, payload) => {
  return [...state, Object.assign({}, payload.nondirect)];
};

export const updateNondirect = (state, payload) => {
  return [
    ...state.filter((nondirect) => nondirect.id !== payload.nondirect.id),
    Object.assign({}, payload.nondirect),
  ];
};

export const deleteNondirect = (state, payload) => {
  return [...state.filter((nondirect) => nondirect.id !== payload.nondirectId)];
};

export const fetchNondirects = (state, payload) => {
  return payload.nondirects;
};

export default createReducer(initialState, {
  [CREATE_NONDIRECT]: createNondirect,
  [UPDATE_NONDIRECT]: updateNondirect,
  [DELETE_NONDIRECT]: deleteNondirect,
  [FETCH_NONDIRECTS]: fetchNondirects,
});
