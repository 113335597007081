import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_DOCUMENT,
  DELETE_DOCUMENT,
  FETCH_DOCUMENTS,
  UPDATE_DOCUMENT,
} from "./documentConstants";

const initialState = [];

export const createDocument = (state, payload) => {
  return [...state, Object.assign({}, payload.document)];
};

export const updateDocument = (state, payload) => {
  return [
    ...state.filter((document) => document.id !== payload.document.id),
    Object.assign({}, payload.document),
  ];
};

export const deleteDocument = (state, payload) => {
  return [...state.filter((document) => document.id !== payload.documentId)];
};

export const fetchDocuments = (state, payload) => {
  return payload.documents;
};

export default createReducer(initialState, {
  [CREATE_DOCUMENT]: createDocument,
  [UPDATE_DOCUMENT]: updateDocument,
  [DELETE_DOCUMENT]: deleteDocument,
  [FETCH_DOCUMENTS]: fetchDocuments,
});
