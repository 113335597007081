import { firebaseReducer } from "react-redux-firebase";
import { reducer as toastrReducer } from "react-redux-toastr";
import { combineReducers } from "redux";
import { firestoreReducer } from "redux-firestore";
import { reducer as FormReducer } from "redux-form";
import asyncReducer from "../../features/async/asyncReducer";
import authReducer from "../../features/auth/authReducer";
import documentReducer from "../../features/document/documentReducer";
import eventReducer from "../../features/event/eventReducer";
import modalsReducer from "../../features/modals/modalReducer";
import nondirectReducer from "../../features/nondirect/nondirectReducer";
import planReducer from "../../features/plan/planReducer";
import reviewReducer from "../../features/review/reviewReducer";
import sessionReducer from "../../features/session/sessionReducer";
import supervisionReducer from "../../features/supervision/supervisionReducer";

const rootReducer = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  form: FormReducer,
  sessions: sessionReducer,
  plans: planReducer,
  documents: documentReducer,
  supervisions: supervisionReducer,
  reviews: reviewReducer,
  events: eventReducer,
  nondirects: nondirectReducer,
  modals: modalsReducer,
  auth: authReducer,
  async: asyncReducer,
  toastr: toastrReducer,
});

export default rootReducer;
