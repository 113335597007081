export const CREATE_PLAN = 'CREATE_PLAN';
export const UPDATE_PLAN = 'UPDATE_PLAN';
export const DELETE_PLAN = 'DELETE_PLAN';
export const FETCH_PLANS = 'FETCH_PLANS';

export const filterOptions = [
  {
    key: 'getPlansForDashboard',
    text: 'All Plans',
    value: 'getPlansForDashboard',
  },
];
