import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_SUPERVISION,
  DELETE_SUPERVISION,
  FETCH_SUPERVISIONS,
  UPDATE_SUPERVISION,
} from "./supervisionConstants";

const initialState = [];

export const createSupervision = (state, payload) => {
  return [...state, Object.assign({}, payload.supervision)];
};

export const updateSupervision = (state, payload) => {
  return [
    ...state.filter((supervision) => supervision.id !== payload.supervision.id),
    Object.assign({}, payload.supervision),
  ];
};

export const deleteSupervision = (state, payload) => {
  return [
    ...state.filter((supervision) => supervision.id !== payload.supervisionId),
  ];
};

export const fetchSupervisions = (state, payload) => {
  return payload.supervisions;
};

export default createReducer(initialState, {
  [CREATE_SUPERVISION]: createSupervision,
  [UPDATE_SUPERVISION]: updateSupervision,
  [DELETE_SUPERVISION]: deleteSupervision,
  [FETCH_SUPERVISIONS]: fetchSupervisions,
});
