export const CREATE_SUPERVISION = 'CREATE_SUPERVISION';
export const UPDATE_SUPERVISION = 'UPDATE_SUPERVISION';
export const DELETE_SUPERVISION = 'DELETE_SUPERVISION';
export const FETCH_SUPERVISIONS = 'FETCH_SUPERVISIONS';

export const filterOptions = [
  {
    key: 'getSupervisionsForDashboard',
    text: 'Newest',
    value: 'getSupervisionsForDashboard',
  },
  {
    key: 'getCommentedSupervisionsForDashboard',
    text: 'Commented',
    value: 'getCommentedSupervisionsForDashboard',
  },
  {
    key: 'getIncompleteSupervisionsForDashboard',
    text: 'Incomplete',
    value: 'getIncompleteSupervisionsForDashboard',
  },
  {
    key: 'getMonthlySupervisionsForDashboard',
    text: 'Family',
    value: 'getMonthlySupervisionsForDashboard',
  },
  {
    key: 'getBhpSupervisionsForDashboard',
    text: 'BHP',
    value: 'getBhpSupervisionsForDashboard',
  },
  {
    key: 'getMGSupervisionsForDashboard',
    text: 'M&G',
    value: 'getMGSupervisionsForDashboard',
  },
  {
    key: 'getGeneralSupervisionsForDashboard',
    text: 'General',
    value: 'getGeneralSupervisionsForDashboard',
  },
  {
    key: 'getOptOutSupervisionsForDashboard',
    text: 'Opt-Out',
    value: 'getOptOutSupervisionsForDashboard',
  },
];

export const filterOptionsPm = [
  {
    key: 'getSupervisionsForDashboard',
    text: 'Newest',
    value: 'getSupervisionsForDashboard',
  },
  {
    key: 'getCommentedSupervisionsForDashboard',
    text: 'Commented',
    value: 'getCommentedSupervisionsForDashboard',
  },
  {
    key: 'getIncompleteSupervisionsForPmDashboard',
    text: 'Incomplete: My Clients',
    value: 'getIncompleteSupervisionsForPmDashboard',
  },
  {
    key: 'getIncompleteSupervisionsForDashboard',
    text: 'Incomplete: Oldest',
    value: 'getIncompleteSupervisionsForDashboard',
  },
  {
    key: 'getMonthlySupervisionsForDashboard',
    text: 'Family',
    value: 'getMonthlySupervisionsForDashboard',
  },
  {
    key: 'getBhpSupervisionsForDashboard',
    text: 'BHP',
    value: 'getBhpSupervisionsForDashboard',
  },
  {
    key: 'getMGSupervisionsForDashboard',
    text: 'M&G',
    value: 'getMGSupervisionsForDashboard',
  },
  {
    key: 'getGeneralSupervisionsForDashboard',
    text: 'General',
    value: 'getGeneralSupervisionsForDashboard',
  },
  {
    key: 'getOptOutSupervisionsForDashboard',
    text: 'Opt-Out',
    value: 'getOptOutSupervisionsForDashboard',
  },
];

export const filterOptionsAuditor = [
  {
    key: 'getSupervisionsForDashboard',
    text: 'Sort by: Newest',
    value: 'getSupervisionsForDashboard',
  },
  {
    key: 'getOldestSupervisionsForDashboard',
    text: 'Sort by: Oldest',
    value: 'getOldestSupervisionsForDashboard',
  },
];
