export const CREATE_REVIEW = 'CREATE_REVIEW';
export const UPDATE_REVIEW = 'UPDATE_REVIEW';
export const DELETE_REVIEW = 'DELETE_REVIEW';
export const FETCH_REVIEWS = 'FETCH_REVIEWS';

export const reviewPrompt = [
  {
    key: 'Regressed greatly',
    text: 'Regressed greatly',
    value: 'Regressed greatly',
  },
  {
    key: 'Regressed somewhat',
    text: 'Regressed somewhat',
    value: 'Regressed somewhat',
  },
  { key: 'Stayed the same', text: 'Stayed the same', value: 'Stayed the same' },
  {
    key: 'Improved somewhat',
    text: 'Improved somewhat',
    value: 'Improved somewhat',
  },
  {
    key: 'Improved greatly',
    text: 'Improved greatly',
    value: 'Improved greatly',
  },
  { key: 'Masted', text: 'Mastered', value: 'Mastered' },
];

export const filterOptions = [
  {
    key: 'getReviewsForDashboard',
    text: 'All Reviews',
    value: 'getReviewsForDashboard',
  },
  {
    key: 'getCsrReviewsForDashboard',
    text: 'CSR',
    value: 'getCsrReviewsForDashboard',
  },
  {
    key: 'get90DayReviewsForDashboard',
    text: '90 Day',
    value: 'get90DayReviewsForDashboard',
  },
  {
    key: 'getIncompleteReviewsForDashboard',
    text: 'Incomplete',
    value: 'getIncompleteReviewsForDashboard',
  },
  {
    key: 'getCommentedReviewsForDashboard',
    text: 'Commented',
    value: 'getCommentedReviewsForDashboard',
  },
];

export const filterOptionsAuditor = [
  {
    key: 'getReviewsForDashboard',
    text: 'Sort by: Newest',
    value: 'getReviewsForDashboard',
  },
  {
    key: 'getOldestReviewsForDashboard',
    text: 'Sort by: Oldest',
    value: 'getOldestReviewsForDashboard',
  },
];
