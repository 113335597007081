import { createReducer } from "../../app/common/util/reducerUtil";
import {
  CREATE_REVIEW,
  DELETE_REVIEW,
  FETCH_REVIEWS,
  UPDATE_REVIEW,
} from "./reviewConstants";

const initialState = [];

export const createReview = (state, payload) => {
  return [...state, Object.assign({}, payload.review)];
};

export const updateReview = (state, payload) => {
  return [
    ...state.filter((review) => review.id !== payload.review.id),
    Object.assign({}, payload.review),
  ];
};

export const deleteReview = (state, payload) => {
  return [...state.filter((review) => review.id !== payload.reviewId)];
};

export const fetchReviews = (state, payload) => {
  return payload.reviews;
};

export default createReducer(initialState, {
  [CREATE_REVIEW]: createReview,
  [UPDATE_REVIEW]: updateReview,
  [DELETE_REVIEW]: deleteReview,
  [FETCH_REVIEWS]: fetchReviews,
});
