export const CREATE_NONDIRECT = 'CREATE_NONDIRECT';
export const UPDATE_NONDIRECT = 'UPDATE_NONDIRECT';
export const DELETE_NONDIRECT = 'DELETE_NONDIRECT';
export const FETCH_NONDIRECTS = 'FETCH_NONDIRECTS';

export const filterOptions = [
  {
    key: 'getNondirectsForDashboard',
    text: 'All Nondirects',
    value: 'getNondirectsForDashboard',
  },
  {
    key: 'getIncompleteNondirectsForDashboard',
    text: 'Incomplete',
    value: 'getIncompleteNondirectsForDashboard',
  },
  {
    key: 'getEventNondirectsForDashboard',
    text: 'Events',
    value: 'getEventNondirectsForDashboard',
  },
  {
    key: 'getGroupNondirectsForDashboard',
    text: 'Group Supervisions',
    value: 'getGroupNondirectsForDashboard',
  },
  {
    key: 'getMGNondirectsForDashboard',
    text: 'M&Gs',
    value: 'getMGNondirectsForDashboard',
  },
  {
    key: 'getOrientationNondirectsForDashboard',
    text: 'Orientations',
    value: 'getOrientationNondirectsForDashboard',
  },
  {
    key: 'getOtherNondirectsForDashboard',
    text: 'Other',
    value: 'getOtherNondirectsForDashboard',
  },
  {
    key: 'getReimbursementNondirectsForDashboard',
    text: 'Reimbursements',
    value: 'getReimbursementNondirectsForDashboard',
  },
  {
    key: 'getShadowNondirectsForDashboard',
    text: 'Shadows',
    value: 'getShadowNondirectsForDashboard',
  },
  {
    key: 'getTrainingNondirectsForDashboard',
    text: 'Trainings',
    value: 'getTrainingNondirectsForDashboard',
  },
];
