export const CREATE_SESSION = 'CREATE_SESSION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const DELETE_SESSION = 'DELETE_SESSION';
export const FETCH_SESSIONS = 'FETCH_SESSIONS';

export const category = [
  { key: 'Communication', text: 'Communication', value: 'Communication' },
  {
    key: 'CommunicationSkills',
    text: 'Communication Skills',
    value: 'Communication Skills',
  },
  {
    key: 'CommunicationEmotionalAwareness',
    text: 'Communication & Emotional Awareness',
    value: 'Communication & Emotional Awareness',
  },
  {
    key: 'CommunicationSocialization',
    text: 'Communication & Socialization',
    value: 'Communication & Socialization',
  },
  {
    key: 'CommunicationSocializationSkills',
    text: 'Communication & Socialization Skills',
    value: 'Communication & Socialization Skills',
  },
  {
    key: 'CommunicationEmotionalRegulation',
    text: 'Communication & Emotional Regulation',
    value: 'Communication & Emotional Regulation',
  },
  { key: 'DailyLiving', text: 'Daily Living', value: 'Daily Living' },
  {
    key: 'DailyLivingSkills',
    text: 'Daily Living Skills',
    value: 'Daily Living Skills',
  },
  {
    key: 'DailyLivingSkillsSafetyAwareness',
    text: 'Daily Living Skills & Safety Awareness',
    value: 'Daily Living Skills & Safety Awareness',
  },
  {
    key: 'EmotionalAwareness',
    text: 'Emotional Awareness',
    value: 'EmotionalAwareness',
  },
  {
    key: 'FunctionalCommunicationSkills',
    text: 'Functional Communication Skills',
    value: 'Functional Communication Skills',
  },
  {
    key: 'IndependentLivingSkills',
    text: 'Independent Living Skills',
    value: 'Independent Living Skills',
  },
  {
    key: 'PlaySkills',
    text: 'Play Skills',
    value: 'PlaySkills',
  },
  { key: 'Safety', text: 'Safety', value: 'Safety' },
  { key: 'SafetySkills', text: 'Safety Skills', value: 'Safety Skills' },
  { key: 'Socialization', text: 'Socialization', value: 'Socialization' },
  {
    key: 'SocializationSkills',
    text: 'Socialization Skills',
    value: 'Socialization Skills',
  },
  {
    key: 'SocializationEmotionalRegulation',
    text: 'Socialization & Emotional Regulation',
    value: 'Socialization & Emotional Regulation',
  },
  {
    key: 'SocialSkillsSafetyAwareness',
    text: 'Social Skills & Safety Awareness',
    value: 'Social Skills & Safety Awareness',
  },
];

export const prompt = [
  { key: 'Indirect Verbal', text: 'Indirect Verbal', value: 'Indirect Verbal' },
  { key: 'Direct Verbal', text: 'Direct Verbal', value: 'Direct Verbal' },
  { key: 'Gesture', text: 'Gesture', value: 'Gesture' },
  { key: 'Modeling', text: 'Modeling', value: 'Modeling' },
  { key: 'Hand-over-hand', text: 'Hand-over-hand', value: 'Hand-over-hand' },
  { key: 'Refused', text: 'Refused', value: 'Refused' },
  {
    key: 'Independent',
    text: 'Independent',
    value: 'Independent',
  },
];

export const filterOptions = [
  {
    key: 'getSessionsForDashboard',
    text: 'Sort by: Newer',
    value: 'getSessionsForDashboard',
  },
  {
    key: 'getOldestSessionsForDashboard',
    text: 'Sort by: Older',
    value: 'getOldestSessionsForDashboard',
  },
  {
    key: 'getCommentedSessionsForDashboard',
    text: 'Sort by: Comment',
    value: 'getCommentedSessionsForDashboard',
  },
  {
    key: 'getIncompleteSessionsForDashboard',
    text: 'Incomplete: Newer',
    value: 'getIncompleteSessionsForDashboard',
  },
  {
    key: 'getIncompleteOldestSessionsForDashboard',
    text: 'Incomplete: Older',
    value: 'getIncompleteOldestSessionsForDashboard',
  },
  {
    key: 'getUnbilledSessionsForDashboard',
    text: 'Unbilled Sessions',
    value: 'getUnbilledSessionsForDashboard',
  },
];

export const filterOptionsPm = [
  {
    key: 'getSessionsForDashboard',
    text: 'Sort by: Newer',
    value: 'getSessionsForDashboard',
  },
  {
    key: 'getOldestSessionsForDashboard',
    text: 'Sort by: Older',
    value: 'getOldestSessionsForDashboard',
  },
  {
    key: 'getCommentedSessionsForDashboard',
    text: 'Sort by: Comment',
    value: 'getCommentedSessionsForDashboard',
  },
  {
    key: 'getIncompleteSessionsForDashboard',
    text: 'Incomplete: Newer',
    value: 'getIncompleteSessionsForDashboard',
  },
  {
    key: 'getIncompleteOldestSessionsForDashboard',
    text: 'Incomplete: Older',
    value: 'getIncompleteOldestSessionsForDashboard',
  },
];

export const filterOptionsAuditor = [
  {
    key: 'getSessionsForDashboard',
    text: 'Sort by: Newer',
    value: 'getSessionsForDashboard',
  },
  {
    key: 'getOldestSessionsForDashboard',
    text: 'Sort by: Older',
    value: 'getOldestSessionsForDashboard',
  },
];

export const filterOptionsBhp = [
  {
    key: 'getSessionsForDashboard',
    text: 'All Sessions',
    value: 'getSessionsForDashboard',
  },
  {
    key: 'getIncompleteOldestSessionsForDashboard',
    text: 'Incomplete',
    value: 'getIncompleteOldestSessionsForDashboard',
  },
];
