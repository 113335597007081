import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/functions';

// production
const firebaseConfig = {
  apiKey: 'AIzaSyDz8Ymk3FNGiAfC1kqvqH6b4psOYkRn7Lc',
  authDomain: 'reactportal-5992d.firebaseapp.com',
  databaseURL: 'https://reactportal-5992d.firebaseio.com',
  projectId: 'reactportal-5992d',
  storageBucket: 'reactportal-5992d.appspot.com',
  messagingSenderId: '164563547928',
};

//dev
// const firebaseConfig = {
//   apiKey: "AIzaSyByDJdsCK73iEyXK_2GdfOtVNT97Iw5-gI",
//   authDomain: "reactportal-225702.firebaseapp.com",
//   databaseURL: "https://reactportal-225702.firebaseio.com",
//   projectId: "reactportal-225702",
//   storageBucket: "reactportal-225702.appspot.com",
//   messagingSenderId: "208764478472",
// };

firebase.initializeApp(firebaseConfig);
const firestore = firebase
  .firestore()
  .enablePersistence()
  .catch(function (err) {
    if (err.code == 'unimplemented') {
      console.log(
        'The current browser does not support all of the features required to enable persistence',
      );
    }
  });
// const settings = {};
// firestore.settings(settings);
firebase.functions();

export default firebase;
